var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"l-gutters"},[_c('div',{staticClass:"l-container"},[_c('h1',{staticClass:"mt-11"},[_vm._v("Creative Centre")]),(_vm.quickLinks && _vm.showQuickLinks)?_c('v-container',{staticClass:"quicklinks"},[_c('v-row',{staticClass:"mb-16"},_vm._l((_vm.quickLinks),function(link){return _c('v-col',{key:link.title,attrs:{"cols":"12","xl":"6"}},[_c('image-text-card',{attrs:{"card-details":link}})],1)}),1)],1):_vm._e(),(_vm.showCategories)?_c('h3',{staticClass:"title font-weight-medium mt-10"},[_vm._v(" Campaign Categories ")]):_vm._e(),(_vm.showCategories)?_c('v-chip-group',{staticClass:"campaign-categories mt-2"},_vm._l((_vm.campaignCategories),function(category){return _c('v-chip',{key:category.id,attrs:{"color":"grey lighten-4","to":{
          name: 'creativecentre.campaignCategory',
          params: { category: category.id },
        }}},[_vm._v(" "+_vm._s(category.title)+" ")])}),1):_vm._e(),(_vm.recentAssets)?[_c('h3',{staticClass:"title font-weight-medium mt-10"},[_vm._v("Recent Campaign Assets")]),_vm._l((_vm.recentAssets),function(category){return _c('div',{key:category.id,staticClass:"mt-6"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(category.title)+" ")]),_c('v-spacer'),_c('router-link',{staticClass:"red--text go-to-calendar",attrs:{"to":{
                  name: 'assetlibrary.category',
                  params: { categoryId: category.id },
                }}},[_vm._v(" View all ")])],1)])],1),_c('v-row',_vm._l((category.assets),function(asset){return _c('v-col',{key:asset.id,attrs:{"sm":"6","md":"4"}},[(!asset.isVideoAsset)?_c('asset-card',{attrs:{"asset":asset}}):_vm._e(),(asset.isVideoAsset)?_c('video-card',{attrs:{"tvc":asset}}):_vm._e()],1)}),1)],1)})]:_vm._e(),_c('upcoming-posts-sidebar',{attrs:{"show-calendar-link":"true","upcoming-posts":_vm.moduleUpcomingPosts},on:{"show-post-detail":_vm.showEvent}}),(_vm.selectedPost)?_c('post-detail-sidebar',{attrs:{"show-drawer":_vm.showPostDetailSidebar,"post":_vm.selectedPost},on:{"close-drawer":function($event){_vm.showPostDetailSidebar = false},"post-updated":_vm.handlePostUpdated,"update:showDrawer":function($event){_vm.showPostDetailSidebar=$event},"update:show-drawer":function($event){_vm.showPostDetailSidebar=$event}}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }